import { FC, isValidElement, ReactNode } from "react";
import { styled, useTheme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import MuiDialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import MuiDialogContent, { DialogContentProps } from "@mui/material/DialogContent";
import MuiDialogActions, { DialogActionsProps } from "@mui/material/DialogActions";
import { NiceModalHandler } from "@ebay/nice-modal-react";
import InlineContainer from "@/components/InlineContainer";
import renderByChildType from "@/utils/renderByChildType";
import useAppSelector from "@/hooks/useAppSelector";
import { selectIsPartner } from "@/features/layout/layout-selectors";

const CustomDialogTitle = styled(MuiDialogTitle, {
  shouldForwardProp: (prop) => prop !== "mode" && prop !== "isPartner",
})<{ mode?: string; isPartner?: boolean }>(
  ({ theme, mode = "light" }) => `
  padding: ${theme.spacing(1.5, 2.5)};
  background-color: ${mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.contrastText};
  color: ${mode === "dark" ? theme.palette.primary.contrastText : theme.palette.primary.dark};
`,
);

export const DialogTitle: FC<DialogTitleProps & { mode?: string }> = ({ children, ...props }) => {
  const isPartner = useAppSelector(selectIsPartner);
  return (
    <CustomDialogTitle isPartner={isPartner} {...props}>
      {children}
    </CustomDialogTitle>
  );
};

const CustomDialogContent = styled(MuiDialogContent)(
  ({ theme }) => `
  padding: ${theme.spacing(2.5)};
  width: 100%;
`,
);

export const DialogContent: FC<DialogContentProps> = ({ children, ...props }) => (
  <CustomDialogContent dividers {...props}>
    {children}
  </CustomDialogContent>
);

const CustomDialogActions = styled(MuiDialogActions)(
  ({ theme }) => `
  padding: ${theme.spacing(1.5, 2.5)};
`,
);

export const DialogActions: FC<DialogActionsProps> = ({ children, ...props }) => (
  <CustomDialogActions {...props}>{children}</CustomDialogActions>
);

const StyledTitle = styled(Typography)(
  ({ theme }) => `
  color: inherit;
  padding-left: 30px;
  text-align: center;
  width: 100%;
  
  ${theme.breakpoints.up("md")} {
    padding-left: 0;
    text-align: left;
  }
`,
);

const CloseButton = styled(IconButton)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
  position: relative;
  right: -6;
`,
);

type CustomDialogProps = Omit<DialogProps, "open" | "title"> & {
  modal?: NiceModalHandler;
  open?: boolean;
  title?: ReactNode;
  actions?: ReactNode;
  hideCloseButton?: boolean;
  hideTitle?: boolean;
  titleMode?: "light" | "dark" | string;
  titleProps?: { [key: string]: any };
  preventCloseOn?: string | string[];
  onClose?: (args?: any[]) => void;
  onExited?: () => void;
};

export const Dialog: FC<CustomDialogProps> = ({
  modal,
  actions = null,
  title = null,
  titleProps = {},
  hideTitle = false,
  hideCloseButton = false,
  titleMode = "light",
  onClose,
  onExited,
  children,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isPartner = useAppSelector(selectIsPartner);

  const closeBlockers =
    typeof props?.preventCloseOn === "string"
      ? [props?.preventCloseOn]
      : Array.isArray(props?.preventCloseOn)
        ? props?.preventCloseOn
        : [];

  const handleClose = (...args: any[]) => {
    if (closeBlockers?.includes(args?.[1])) {
      return;
    }

    if (typeof onClose === "function") {
      onClose(args);
    }

    modal?.reject();
    modal?.hide();
  };

  const filteredChildren = renderByChildType([DialogTitle, DialogActions, DialogContent], children, true);

  return (
    <MuiDialog
      open={Boolean(modal?.visible)}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      {...props}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => {
          if (typeof onExited === "function") {
            onExited();
          }
          modal?.remove();
        },
      }}
      sx={{ ...props?.sx }}
    >
      {hideTitle ? null : renderByChildType(DialogTitle, children) || isValidElement(title) ? (
        title
      ) : (
        <DialogTitle mode={titleMode}>
          <InlineContainer justifyContent="space-between">
            {!Boolean(title) ? (
              <Box />
            ) : (
              <StyledTitle
                color="primary"
                variant={titleMode === "dark" ? "h5" : "h6"}
                {...titleProps}
                sx={{
                  ...(titleMode === "dark" ? { paddingLeft: { xs: 0 }, textAlign: { xs: "left" } } : {}),
                }}
              >
                {title}
              </StyledTitle>
            )}
            {!hideCloseButton && (
              <CloseButton aria-label="close" size="small" onClick={handleClose} edge="end">
                <CloseIcon
                  fontSize="small"
                  sx={{
                    color: isPartner ? "primary.dark" : titleMode === "dark" ? "primary.contrastText" : "primary.dark",
                  }}
                />
              </CloseButton>
            )}
          </InlineContainer>
        </DialogTitle>
      )}
      {renderByChildType(DialogContent, children) || <DialogContent>{filteredChildren}</DialogContent>}
      {renderByChildType(DialogActions, children) || (Boolean(actions) && <DialogActions>{actions}</DialogActions>)}
    </MuiDialog>
  );
};

export default Dialog;
