import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { DateTime } from "luxon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NeutralButton from "@/components/buttons/NeutralButton";
import useAppSelector from "@/hooks/useAppSelector";
import { selectInvoice } from "@/features/invoice-management/invoice-management-selectors";
import { getActionButtonText, getInvoiceAction } from "@/utils/invoices";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMediaQuery, useTheme } from "@mui/material";

export default NiceModal.create(() => {
  const modal = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleCloseModal = () => {
    modal.hide();
  };
  const invoice = useAppSelector(selectInvoice);

  const action = getInvoiceAction(invoice);

  const isSchedule = action === "schedule";

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.dark",
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            {isSchedule ? "Schedule " : "Send "}Invoice
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: "grey.500",
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "2rem 1.25rem" }} dividers>
        <Typography variant="body1">
          {`Are you sure you want to send this Batch Invoice to the following recipients on ${DateTime.fromISO(
            invoice?.due_at ?? "",
          ).toFormat("MM/dd/yyyy")}?`}
        </Typography>
        <Grid container spacing={1} marginTop=".5rem">
          {invoice?.customers?.map((customer) => (
            <Grid item key={customer.id} xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: "primary.main",
                  padding: "2px",
                }}
              >
                <Typography variant="body2" noWrap>
                  {customer.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Typography variant="body1" marginTop="1rem">
          When you {isSchedule ? "schedule" : "send out"} your Batch Invoice, multiple separate invoices will be created
          for each Batch Invoice receipient that can be found invididually on the Invoice Table.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            disableElevation
            onClick={() => {
              modal.reject();
              handleCloseModal();
            }}
            sx={{ fontSize: isMobile ? "small" : "medium" }}
          >
            No, Cancel
          </NeutralButton>
          <AffirmativeButton
            onClick={() => {
              modal.resolve();
              handleCloseModal();
            }}
            sx={{ fontSize: isMobile ? "small" : "medium" }}
          >
            Yes, {isSchedule ? "Schedule" : "Send"} Invoice
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
