import sortBy from "lodash/sortBy";
import { Quote, QuoteStatus, QuoteItem, QuoteCustomer, Account } from "@/types";
import { INDIVIDUAL_ITEMS } from "@/features/quote-management/quote-management-reducers";
import { setQuote } from "@/features/quote-management/quote-management-reducers";
import { Dispatch } from "react";
import stringifyQueryParams from "@/utils/stringifyQueryParams";
import { compliancePassed } from "./compliance";

export const quoteRedirectFilters = stringifyQueryParams({
  page: 1,
  page_size: 10,
  sort_field: "created_at",
  sort_direction: "DESC",
});

export const handleAddCustomerToQuote = (dispatch: Dispatch<any>, customers: QuoteCustomer[], quote: Quote) => {
  let updatedCustomers: QuoteCustomer[] = [];

  customers?.forEach((customer) => {
    const email = customer?.email || customer?.user?.email;
    const cell_phone = customer?.cell_phone || customer?.user?.cell_phone;

    updatedCustomers =
      updatedCustomers?.map((existingCustomer) => {
        // Check if the customer already exists
        if (existingCustomer.id === customer.id || existingCustomer.email === email) {
          // If the existing customer matches, update the email and cell_phone
          return {
            ...existingCustomer,
            email,
            cell_phone,
          };
        }
        // If the existing customer doesn't match, return it as it is
        return existingCustomer;
      }) ?? [];

    // Check if the customer already exists in the array
    const customerExists = updatedCustomers?.some(
      (existingCustomer) => existingCustomer.id === customer.id || existingCustomer.email === email,
    );

    // If the customer doesn't exist, add it to the end of the array
    if (!customerExists) {
      updatedCustomers?.push({
        ...customer,
        email,
        cell_phone,
      });
    }
  });

  dispatch(
    setQuote({
      ...quote,
      has_changed: true,
      customers: updatedCustomers,
    }),
  );
};

export const getQuoteAction = (quote: Quote, account?: Account) => {
  if (!compliancePassed(account)) {
    return "draft";
  }

  const hasOneCustomer = Boolean(quote?.customers?.length === 1) || Boolean(quote?.account_customer_id);

  const isQuoteSent = quote?.status === QuoteStatus.SENT;

  if (quote?.status === QuoteStatus.DRAFT) {
    if (hasOneCustomer) {
      return "now";
    }

    return "draft";
  } else if (quote?.has_changed && hasOneCustomer) {
    if (isQuoteSent) {
      return "save-resend";
    }

    return "save";
  } else if (isQuoteSent) {
    return "resend";
  } else if (quote?.status === QuoteStatus.APPROVED) {
    return "convert";
  } else if (quote?.status === QuoteStatus.INVOICED) {
    return "invoice";
  }

  return "save";
};

export const getActionButtonText = (quote: Quote, account?: Account) => {
  const action = getQuoteAction(quote, account);

  if (action === "save-resend") {
    return "Save & Resend";
  }

  if (quote.has_changed && action === "save") {
    return "Save Changes";
  }

  if (action === "draft") {
    return "Save Draft";
  }

  if (action === "resend") {
    return "Resend";
  }

  if (action === "now") {
    return "Send Now";
  }

  if (action === "convert") {
    return "Convert to Invoice";
  }

  if (action === "invoice") {
    return "View Invoice";
  }
};

export const calculateItemGroup = (items: QuoteItem[] = []) => {
  const c: { [x: string]: QuoteItem[] } = {
    [INDIVIDUAL_ITEMS]: [],
  };
  const categorySortOrder = {
    [INDIVIDUAL_ITEMS]: 0,
  };
  items?.forEach((i, idx) => {
    if (i.account_customer_category_id && !c[i.account_customer_category_id]) {
      c[i.account_customer_category_id] = [];
      if (i.account_customer_category_id !== INDIVIDUAL_ITEMS) {
        categorySortOrder[i.account_customer_category_id] = Object.keys(categorySortOrder).length;
      }
    }

    c[i.account_customer_category_id || INDIVIDUAL_ITEMS].push({
      ...i,
      sort_order: idx,
    });
  });

  const categories = sortBy(Object.keys(c), (k) => categorySortOrder[k]);

  return {
    categories,
    itemDict: c,
    categorySortOrder,
  };
};
