// The purpose of this files it to provide a way to easily navigate
// between onboarding steps and makes the onboarding steps maintainable
// from this file instead of having to change the steps in multiple places.

import { Account, AccountStatuses, User } from "@/types";

export enum OnboardingSteps {
  USER_PASSWORD = "USER_PASSWORD",
  USER_NAME = "USER_NAME",
  USER_DASHBOARD = "USER_DASHBOARD",
  BUSINESS_USER_DEMOGRAPHIC = "BUSINESS_USER_DEMOGRAPHIC",
  BUSINESS_DETAIL = "BUSINESS_DETAIL",
  BUSINESS_SELECT_PLAN = "BUSINESS_SELECT_PLAN",
  BUSINESS_AGREE_PARTNER_PLAN = "BUSINESS_AGREE_PARTNER_PLAN",
  BUSINESS_DASHBOARD = "BUSINESS_DASHBOARD",
}

// The key is the step number, and the value is the path
export const onboardingPaths = {
  [OnboardingSteps.USER_PASSWORD]: "/onboarding/create-password",
  [OnboardingSteps.USER_NAME]: "/onboarding/full-name",
  [OnboardingSteps.USER_DASHBOARD]: "/user/payments",
  [OnboardingSteps.BUSINESS_USER_DEMOGRAPHIC]: "/onboarding/demographic",
  [OnboardingSteps.BUSINESS_DETAIL]: "/onboarding/business-details",
  [OnboardingSteps.BUSINESS_SELECT_PLAN]: "/onboarding/select-plan",
  [OnboardingSteps.BUSINESS_AGREE_PARTNER_PLAN]: "/onboarding/agree-partner-plan",
  [OnboardingSteps.BUSINESS_DASHBOARD]: "/?welcome=true",
};

export const getOnboardingSteps = ({
  user,
  account,
  step,
}: {
  user?: User;
  account?: Account;
  step: OnboardingSteps;
}): {
  steps: OnboardingSteps[];
  previousPath: string | null;
  nextPath: string;
  redirectPath: string | null;
} => {
  const userOnboardingSteps: OnboardingSteps[] = user?.must_finish_user_setup
    ? [...(!user?.has_password ? [OnboardingSteps.USER_PASSWORD] : []), OnboardingSteps.USER_NAME]
    : [];

  let accountOnboardingSteps: OnboardingSteps[] = [];

  if (account && account?.status !== AccountStatuses.ACTIVE) {
    accountOnboardingSteps = [
      ...(account?.partnership?.does_collect_merchant_demographic_data
        ? [OnboardingSteps.BUSINESS_USER_DEMOGRAPHIC]
        : []),
      OnboardingSteps.BUSINESS_DETAIL,
      account?.partnership?.does_pay_merchant_subscription
        ? OnboardingSteps.BUSINESS_AGREE_PARTNER_PLAN
        : OnboardingSteps.BUSINESS_SELECT_PLAN,
    ];
  }

  const steps = [...userOnboardingSteps, ...accountOnboardingSteps];

  const index = steps.indexOf(step);

  let redirectPath: string | null = null;

  if (!steps.includes(step)) {
    redirectPath =
      onboardingPaths[steps[0] || (account ? OnboardingSteps.BUSINESS_DASHBOARD : OnboardingSteps.USER_DASHBOARD)];
  }

  return {
    steps,
    previousPath: onboardingPaths[steps?.[index - 1]] || null,
    nextPath:
      onboardingPaths[
        steps?.[index + 1] || (account ? OnboardingSteps.BUSINESS_DASHBOARD : OnboardingSteps.USER_DASHBOARD)
      ],
    redirectPath,
  };
};
