import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NeutralButton from "@/components/buttons/NeutralButton";
import {
  DialogKeys,
  PaidOfflineType,
  Payment,
  PaymentAction,
  PaymentCreateMethod,
  PaymentCreateTarget,
  Query,
} from "@/types";
import { Box, MenuItem } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import TextField from "@/components/TextField";
import { DateTime } from "luxon";
import DownArrowIcon from "@mui/icons-material/KeyboardArrowDown";
import { fetchInvoice } from "@/features/invoice-management/invoice-management-queries";
import { deletePayment, createPayment } from "@/features/payment-management/payment-management-queries";
import { editOfflinePayment } from "@/features/payment-management/payment-management-queries";
import Select from "@/components/Select";
import { capitalizeFirstLetter } from "@/utils/stringUtils";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { offlinePaymentValidation } from "@/features/invoice-management/invoice-management-validation";
import getModifiedValues from "@/utils/getModifiedValues";
import getFieldErrors from "@/utils/getFieldErrors";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useMessage from "@/hooks/useMessage";
import SystemFeedback from "@/components/SystemFeedback";
import { refetchInvoice } from "@/features/invoice-management/invoice-management-reducers";
import useAppDispatch from "@/hooks/useAppDispatch";
import FormattedNumber from "@/components/FormattedNumber";
import { useRouter } from "next/router";
import ErrorButton from "@/components/buttons/ErrorButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import useAppSelector from "@/hooks/useAppSelector";
import { selectInvoice } from "@/features/invoice-management/invoice-management-selectors";
import { getInvoiceTotals } from "@/utils/calculations";

export default NiceModal.create<{
  invoiceId: string;
  payment?: Payment;
  totalAmount?: number;
}>(({ invoiceId, payment, totalAmount }) => {
  const modal = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [message, setMessage] = useMessage();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const selectedInvoice = useAppSelector(selectInvoice);

  const { invoiceTotal } = getInvoiceTotals(selectedInvoice);

  const { refetch: refetchInvoiceQuery } = useQuery({
    queryKey: [Query.account.INVOICE, invoiceId],
    queryFn: () => fetchInvoice(invoiceId),
    onSuccess: (data) => {
      dispatch(refetchInvoice(data));
    },
    enabled: false,
  });

  const initialValues: {
    invoiceId?: string;
    payment_note: string;
    paid_offline_type: PaidOfflineType | null;
    payment_date: DateTime | null;
  } = {
    invoiceId,
    payment_note: payment?.payment_note ?? "",
    paid_offline_type: payment?.paid_offline_type ?? null,
    payment_date: payment?.payin_at ? DateTime.fromMillis(Date.parse(payment?.payin_at)) : DateTime.now(),
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema: offlinePaymentValidation,
    onSubmit: async (values) => {
      if (!values?.paid_offline_type || !values?.payment_date) {
        return;
      }

      if (payment) {
        editPayment.mutate({
          paymentId: payment.id,
          invoiceId: payment.invoice_id,
          payment_note: values?.payment_note,
          paid_offline_type: values?.paid_offline_type,
          payment_date: values?.payment_date?.toFormat("yyyy-MM-dd"),
        });
      } else {
        createOfflinePayment.mutate({
          invoiceId,
          payment_note: values?.payment_note,
          paid_offline_type: values?.paid_offline_type,
          payment_date: values?.payment_date?.toFormat("yyyy-MM-dd"),
        });
      }
    },
  });

  const handleSuccess = async () => {
    if (router.pathname.includes("[...invoiceId]")) {
      refetchInvoiceQuery();
    } else if (router.pathname.includes("/invoices") && !router.pathname.includes("[...invoiceId]")) {
      queryClient.invalidateQueries([Query.account.INVOICES_PAGINATION]);
    } else if (router.pathname.includes("/payments")) {
      queryClient.invalidateQueries([Query.account.PAYMENTS]);
    }
    modal.resolve();
    modal.hide();
  };

  const createOfflinePayment = useMutation(
    ({ invoiceId, ...body }: any) => {
      const createPaymentPayload = {
        target: {
          type: PaymentCreateTarget.INVOICE,
          id: invoiceId,
        },
        method: PaymentCreateMethod.OFFLINE,
        requestBody: body,
        isBackdoor: false,
      };
      return createPayment(createPaymentPayload);
    },
    {
      onSuccess: () => {
        handleSuccess();
        enqueueSnackbar("Payment created successfully!", {
          variant: "success",
        });
      },
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message ?? "Cannot create offline payment.",
        });
        enqueueSnackbar("Failed to create payment", { variant: "error" });
      },
    },
  );

  const editPayment = useMutation((values: any) => editOfflinePayment(values), {
    onSuccess: () => {
      handleSuccess();
      enqueueSnackbar("Payment updated successfully!", {
        variant: "success",
      });
    },
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error?.response?.data?.message ?? "Cannot edit offline payment.",
      });
      enqueueSnackbar("Failed to edit payment", { variant: "error" });
    },
  });

  const { mutate: performDelete } = useMutation(() => deletePayment(payment?.id), {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.account.PAYMENTS]);
      queryClient.invalidateQueries([Query.account.INVOICES_PAGINATION]);
      queryClient.invalidateQueries([Query.account.INVOICE, invoiceId]);
      enqueueSnackbar("Payment deleted successfully!", {
        variant: "success",
      });
      refetchInvoiceQuery();
    },
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error?.response?.data?.message ?? "Cannot delete payment.",
      });
      enqueueSnackbar("Failed to delete payment", { variant: "error" });
    },
  });

  const modifiedValues = getModifiedValues(formik.values, formik.initialValues);

  const handleClose = () => {
    modal.hide();
  };

  const isValidDate = (date: DateTime | null) => {
    if (!date) {
      return false;
    }
    return date.isValid;
  };

  const disableSubmit =
    !formik.isValid ||
    Object.keys(modifiedValues).length === 0 ||
    !isValidDate(modifiedValues["payment_date"] || initialValues["payment_date"]) ||
    formik.isSubmitting;

  const total = (payment?.amount || 0) / 100 || totalAmount || invoiceTotal;

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      onClose={() => {
        modal.hide();
      }}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.dark",
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Record Payment
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleClose}
            sx={{
              color: "grey.500",
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "1rem",
        }}
        dividers
      >
        <SystemFeedback {...message} />
        <Typography variant="body1">Record a payment collected offline for this invoice</Typography>
        <Box display="flex" flexDirection="column" gap=".5rem" marginBottom=".5rem">
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Amount
          </Typography>
          <Typography>
            <FormattedNumber prefix="$" value={total} />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap=".5rem" marginBottom=".5rem">
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Payment Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              {...formik.getFieldProps("payment_date")}
              value={formik?.values?.payment_date ?? undefined}
              disableFuture
              label="Select Payment Date"
              onChange={(newValue) => {
                formik.setFieldValue("payment_date", newValue);
              }}
              format="MM/dd/yyyy"
              slots={{ textField: TextField }}
              slotProps={{
                textField: {
                  size: "small",
                  placeholder: "Payment Date",
                  label: "Payment Date",
                  sx: {
                    width: 160,
                    "& .MuiSvgIcon-root": {
                      fontSize: "20px",
                      color: "grey.600",
                    },
                  },
                  fullWidth: true,
                  ...getFieldErrors("payment_date", formik, {
                    ignoreTouched: true,
                  }),
                },
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box display="flex" flexDirection="column" gap=".5rem" marginBottom=".5rem">
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Payment Method
          </Typography>
          <Select
            defaultValue={""}
            size="small"
            {...formik.getFieldProps("paid_offline_type")}
            {...getFieldErrors("paid_offline_type", formik)}
            color="primary"
            variant="outlined"
            fullWidth
            SelectProps={{
              IconComponent: DownArrowIcon,
            }}
            sx={{
              fontSize: "1rem",
              marginBottom: "1.25rem",
            }}
          >
            {Object.keys(PaidOfflineType).map((payment) => (
              <MenuItem key={payment} value={payment}>
                {capitalizeFirstLetter(payment)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex" flexDirection="column" gap=".5rem" marginBottom=".5rem">
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Notes
          </Typography>
          <TextField
            {...formik.getFieldProps("payment_note")}
            {...getFieldErrors("payment_note", formik)}
            multiline
            rows={3}
            sx={{ width: "100%" }}
            inputProps={{ maxLength: 500 }}
            onChange={(e) => {
              if (e.target.value.length <= 200) {
                formik.setFieldValue("payment_note", e.target.value);
              }
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: (formik?.values?.payment_note.length ?? 0) > 200 ? "error.dark" : "grey.600",
              display: "block",
              margin: "0.5rem 0 0 0",
              textAlign: "right",
            }}
          >
            {formik?.values?.payment_note.length ?? 0} / 200
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <InlineContainer gap="0.5rem">
            <NeutralButton
              disableElevation
              onClick={() => {
                modal.reject();
                modal.hide();
              }}
            >
              Cancel
            </NeutralButton>
            {payment?.allowable_actions?.sub?.includes(PaymentAction.DELETE) && (
              <ErrorButton
                disableElevation
                onClick={() => {
                  NiceModal.show(DialogKeys.CONFIRM, {
                    title: "Delete Payment",
                    content: "Do you want to delete this payment from your payment history?",
                    confirmButton: "Delete",
                    confirmButtonColor: "error.main",
                  }).then(() => {
                    performDelete();
                    modal.hide();
                  });
                }}
              >
                Delete
              </ErrorButton>
            )}
          </InlineContainer>
          <AffirmativeButton
            disabled={disableSubmit}
            onClick={() => {
              formik.handleSubmit();
            }}
            sx={{ minWidth: "100px" }}
          >
            Save
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
