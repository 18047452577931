import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { DateTime } from "luxon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NeutralButton from "@/components/buttons/NeutralButton";
import { Invoice, InvoiceStatus } from "@/types";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { useMediaQuery, useTheme } from "@mui/material";
import TestId from "@/constants/testIds";

export default NiceModal.create<{ invoice: Invoice }>(({ invoice }) => {
  const modal = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleCloseModal = () => {
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.dark",
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Send Invoice
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: "grey.500",
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "2rem 1.25rem" }} dividers>
        {invoice?.status === InvoiceStatus.DRAFT ? (
          <Typography variant="body1">
            Issue date was updated to {DateTime.now().toFormat("MM/dd/yyyy")}. Do you want to send this invoice now?
          </Typography>
        ) : [InvoiceStatus.ISSUED, InvoiceStatus.PAST_DUE].includes(invoice?.status as InvoiceStatus) ? (
          <Typography variant="body1">Are you sure you want to resend this invoice now?</Typography>
        ) : (
          <Typography variant="body1">Are you sure you want to send this invoice now?</Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            disableElevation
            onClick={() => {
              modal.reject();
              handleCloseModal();
            }}
            sx={{ fontSize: isMobile ? "small" : "medium" }}
          >
            No, Cancel
          </NeutralButton>
          <AffirmativeButton
            onClick={() => {
              modal.resolve();
              handleCloseModal();
            }}
            sx={{ fontSize: isMobile ? "small" : "medium" }}
            data-testid={TestId.Dialog.InvoiceConfirmSendInvoice}
          >
            Yes, Send Invoice
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
