import NiceModal from "@ebay/nice-modal-react";
import { DialogKeys } from "@/types";
import AddPriceItemToInvoiceDialog from "@/features/invoice-management/components/AddPriceItemToInvoiceDialog";
import InvoicePriceItemOptionsDialog from "@/features/invoice-management/components/InvoicePriceItemOptionsDialog";
import PriceItemDialog from "@/features/price-management/components/PriceItemDialog";
import PriceModifierDialog from "@/features/price-management/components/PriceModifierDialog";
import AddCustomerToInvoiceDialog from "@/features/invoice-management/components/AddCustomerToInvoiceDialog";
import AddPriceModifierToInvoiceDialog from "@/features/invoice-management/components/AddPriceModifierToInvoiceDialog";
import CustomerDialog from "@/features/customer-management/components/CustomerDialog";
import CustomerCategoryDialog from "@/features/customer-management/components/CustomerCategoryDialog";
import BulkUploadCustomersDialog from "@/features/customer-management/components/BulkUploadCustomersDialog";
import BulkUploadPriceItemsDialog from "@/features/customer-management/components/BulkUploadPriceItemsDialog";
import ConfirmCustomerStatusChange from "@/features/customer-management/components/ConfirmCustomerStatusChange";
import BulkAddTagsDialog from "@/features/customer-management/components/BulkAddTagsDialog";
import InviteMemberDialog from "@/features/account-management/components/InviteMemberDialog";
import ViewMemberDetailsDialog from "@/features/account-management/components/ViewMemberDetailsDialog";
import SendInvoiceConfirmationDialog from "@/features/invoice-management/components/SendInvoiceConfirmationDialog";
import InvoiceSentConfirmationDialog from "@/features/invoice-management/components/InvoiceSentConfirmationDialog";
import LeaveInvoiceConfirmationDialog from "@/features/invoice-management/components/LeaveInvoiceConfirmationDialog";
import InvoicePreviewDialog from "@/features/invoice-management/components/InvoicePreviewDialog";
import ConfirmCancelInvoiceDialog from "@/features/invoice-management/components/ConfirmCancelInvoiceDialog";
import ConfirmDeleteInvoiceDialog from "@/features/invoice-management/components/ConfirmDeleteInvoiceDialog";
import CompleteComplianceDialog from "@/features/onboarding/CompleteComplianceDialog";
import DeactivateCustomerDialog from "@/features/customer-management/components/DeactivateCustomerDialog";
import ActivateCustomerDialog from "@/features/customer-management/components/ActivateCustomerDialog";
import ConfirmSkipInvoiceDialog from "@/features/invoice-management/components/ConfirmSkipInvoiceDialog";
import ConfirmCancelSeriesDialog from "@/features/invoice-management/components/ConfirmCancelSeriesDialog";
import BulkSendEmailDialog from "@/features/customer-management/components/BulkSendEmailDialog";
import ChangePhoneNumberDialog from "@/features/user-management/components/ChangePhoneNumberDialog";
import PasswordVerificationDialog from "@/features/user-management/components/PasswordVerificationDialog";
import BasicConfirmationDialog from "@/components/BasicConfirmationDialog";
import InvoiceScheduledConfirmationDialog from "@/features/invoice-management/components/InvoiceScheduledConfirmationDialog";
import RecordPaymentDialog from "@/features/payment-management/components/RecordPaymentDialog";
import InvoiceCustomerOptionsDialog from "@/features/invoice-management/components/InvoiceCustomerOptionsDialog";
import AcceptInvoiceChangesDialog from "@/features/pay-invoice/AcceptInvoiceChangesDialog";
import AcceptQuoteChangesDialog from "@/features/accept-quote/AcceptQuoteChangesDialog";
import SendInvoiceApprovalDialog from "@/features/invoice-management/components/SendInvoiceApprovalDialog";
import SendBatchInvoiceConfirmationDialog from "@/features/invoice-management/components/SendBatchInvoiceConfirmationDialog";
import CopyLinkDialog from "@/components/CopyLinkDialog";
import AddPriceItemToQuoteDialog from "@/features/quote-management/components/AddPriceItemToQuoteDialog";
import QuotePriceItemOptionsDialog from "@/features/quote-management/components/QuotePriceItemOptionsDialog";
import AddCustomerToQuoteDialog from "@/features/quote-management/components/AddCustomerToQuoteDialog";
import AddPriceModifierToQuoteDialog from "@/features/quote-management/components/AddPriceModifierToQuoteDialog";
import SendQuoteConfirmationDialog from "@/features/quote-management/components/SendQuoteConfirmationDialog";
import QuoteSentConfirmationDialog from "@/features/quote-management/components/QuoteSentConfirmationDialog";
import LeaveQuoteConfirmationDialog from "@/features/quote-management/components/LeaveQuoteConfirmationDialog";
import QuotePreviewDialog from "@/features/quote-management/components/QuotePreviewDialog";
import ConfirmCancelQuoteDialog from "@/features/quote-management/components/ConfirmCancelQuoteDialog";
import ConfirmDeleteQuoteDialog from "@/features/quote-management/components/ConfirmDeleteQuoteDialog";
import SendQuoteApprovalDialog from "@/features/quote-management/components/SendQuoteApprovalDialog";
import WelcomeToFinliDialog from "@/components/WelcomeToFinliDialog";
import InvoiceSyncSaveConfirmationDialog from "../invoice-management/components/InvoiceSyncSaveConfirmationDialog";

NiceModal.register(DialogKeys.INVOICE_CUSTOMER, AddCustomerToInvoiceDialog);
NiceModal.register(DialogKeys.INVOICE_PRICE_ITEM, AddPriceItemToInvoiceDialog);
NiceModal.register(DialogKeys.INVOICE_PRICE_ITEM_OPTIONS, InvoicePriceItemOptionsDialog);
NiceModal.register(DialogKeys.INVOICE_CUSTOMER_OPTIONS, InvoiceCustomerOptionsDialog);
NiceModal.register(DialogKeys.INVOICE_PRICE_MODIFIER, AddPriceModifierToInvoiceDialog);
NiceModal.register(DialogKeys.ACCEPT_INVOICE_CHANGES, AcceptInvoiceChangesDialog);
NiceModal.register(DialogKeys.ACCEPT_QUOTE_CHANGES, AcceptQuoteChangesDialog);

NiceModal.register(DialogKeys.INVOICE_SYNC_SAVE_CONFIRMATION, InvoiceSyncSaveConfirmationDialog);

NiceModal.register(DialogKeys.PRICE_ITEM, PriceItemDialog);
NiceModal.register(DialogKeys.PRICE_MODIFIER, PriceModifierDialog);
NiceModal.register(DialogKeys.CUSTOMER, CustomerDialog);
NiceModal.register(DialogKeys.CUSTOMER_CATEGORY, CustomerCategoryDialog);
NiceModal.register(DialogKeys.CUSTOMER_BULK_UPLOAD, BulkUploadCustomersDialog);
NiceModal.register(DialogKeys.PRICE_ITEM_BULK_UPLOAD, BulkUploadPriceItemsDialog);
NiceModal.register(DialogKeys.CUSTOMER_CONFIRM_STATUS, ConfirmCustomerStatusChange);
NiceModal.register(DialogKeys.CUSTOMER_BULK_TAGS, BulkAddTagsDialog);
NiceModal.register(DialogKeys.CUSTOMER_ACTIVATE, ActivateCustomerDialog);
NiceModal.register(DialogKeys.CUSTOMER_DEACTIVATE, DeactivateCustomerDialog);
NiceModal.register(DialogKeys.MEMBER_INVITE, InviteMemberDialog);
NiceModal.register(DialogKeys.MEMBER_VIEW, ViewMemberDetailsDialog);
NiceModal.register(DialogKeys.SEND_INVOICE_CONFIRMATION, SendInvoiceConfirmationDialog);
NiceModal.register(DialogKeys.SEND_BATCH_INVOICE_CONFIRMATION, SendBatchInvoiceConfirmationDialog);
NiceModal.register(DialogKeys.SEND_INVOICE_APPROVAL, SendInvoiceApprovalDialog);
NiceModal.register(DialogKeys.INVOICE_SENT, InvoiceSentConfirmationDialog);
NiceModal.register(DialogKeys.INVOICE_SCHEDULED, InvoiceScheduledConfirmationDialog);
NiceModal.register(DialogKeys.LEAVE_INVOICE_CONFIRMATION, LeaveInvoiceConfirmationDialog);
NiceModal.register(DialogKeys.CANCEL_INVOICE_CONFIRMATION, ConfirmCancelInvoiceDialog);
NiceModal.register(DialogKeys.SKIP_INVOICE_CONFIRMATION, ConfirmSkipInvoiceDialog);
NiceModal.register(DialogKeys.DELETE_INVOICE_CONFIRMATION, ConfirmDeleteInvoiceDialog);
NiceModal.register(DialogKeys.CANCEL_SERIES_CONFIRMATION, ConfirmCancelSeriesDialog);
NiceModal.register(DialogKeys.INVOICE_PREVIEW, InvoicePreviewDialog);
NiceModal.register(DialogKeys.COMPLETE_COMPLIANCE, CompleteComplianceDialog);
NiceModal.register(DialogKeys.CUSTOMER_BULK_EMAIL, BulkSendEmailDialog);
NiceModal.register(DialogKeys.CHANGE_PHONE_NUMBER, ChangePhoneNumberDialog);
NiceModal.register(DialogKeys.PASSWORD_VERIFICATION_DIALOG, PasswordVerificationDialog);
NiceModal.register(DialogKeys.CONFIRM, BasicConfirmationDialog);
NiceModal.register(DialogKeys.RECORD_PAYMENT, RecordPaymentDialog);
NiceModal.register(DialogKeys.COPY_LINK_DIALOG, CopyLinkDialog);

NiceModal.register(DialogKeys.DELETE_QUOTE_CONFIRMATION, ConfirmDeleteQuoteDialog);
NiceModal.register(DialogKeys.CANCEL_QUOTE_CONFIRMATION, ConfirmCancelQuoteDialog);
NiceModal.register(DialogKeys.QUOTE_PREVIEW, QuotePreviewDialog);
NiceModal.register(DialogKeys.QUOTE_SENT, QuoteSentConfirmationDialog);
NiceModal.register(DialogKeys.QUOTE_CUSTOMER, AddCustomerToQuoteDialog);
NiceModal.register(DialogKeys.QUOTE_PRICE_ITEM, AddPriceItemToQuoteDialog);
NiceModal.register(DialogKeys.QUOTE_PRICE_ITEM_OPTIONS, QuotePriceItemOptionsDialog);
NiceModal.register(DialogKeys.QUOTE_PRICE_MODIFIER, AddPriceModifierToQuoteDialog);
NiceModal.register(DialogKeys.SEND_QUOTE_CONFIRMATION, SendQuoteConfirmationDialog);
NiceModal.register(DialogKeys.SEND_QUOTE_APPROVAL, SendQuoteApprovalDialog);
NiceModal.register(DialogKeys.LEAVE_QUOTE_CONFIRMATION, LeaveQuoteConfirmationDialog);
NiceModal.register(DialogKeys.LEAVE_QUOTE_CONFIRMATION, LeaveQuoteConfirmationDialog);
NiceModal.register(DialogKeys.WELCOME_TO_FINLI, WelcomeToFinliDialog);
