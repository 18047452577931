import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logoutThunk } from "@/features/auth/auth-queries";
import type { Quote, QuoteCustomer } from "@/types";
import { QuoteStatus, SeriesFrequency } from "@/types";
import { HYDRATE } from "next-redux-wrapper";
import { RootState } from "@/config/store";
import assignIn from "lodash/assignIn";

export const INDIVIDUAL_ITEMS = "INDIVIDUAL_ITEMS";

export const defaultSeries = {
  frequency_count: 1,
  frequency: SeriesFrequency.MONTHLY,
  issued_days_before_due_date: 5,
  end_at: null,
  end_after_number_quotes: null,
};

const hydrate = createAction<RootState>(HYDRATE);

// parse quote from server into a redux state
export const parseQuoteResponseToQuoteState = (quote) => {
  const status = quote.status || QuoteStatus.DRAFT;

  const sync_mode = quote?.sync_mode || "always";

  let customers: QuoteCustomer[] = [];

  // If we receive
  if (Object.keys(quote?.customer ?? {}).length) {
    customers = [quote?.customer];
  } else if (Array.isArray(quote?.customers)) {
    customers = quote?.customers;
  }

  const quoteToReturn: Quote = {
    ...quote,
    customers,
    items: reorderQuoteItems(quote?.items),
    status,
    // general data that has changed, used for autosave
    has_changed: false,
    sync_mode,
    // use to force save settings that should be saved right away even though sync_mode = 'ask'
    force_save: false,
    use_statement_period: !!(quote?.statement_period_start || quote?.statement_period_end),
    is_readonly:
      quote?.is_readonly ||
      [
        QuoteStatus.ISSUED,
        QuoteStatus.CANCELED,
        QuoteStatus.DELETED,
        QuoteStatus.APPROVED,
        QuoteStatus.INVOICED,
      ].includes(status),
  };

  delete quoteToReturn.customer;

  return quoteToReturn;
};

export const reorderQuoteItems = (items) => {
  const incomingItems: any[] = [[]];

  const categories = {};

  items?.forEach((item) => {
    if (!item.account_customer_category_id || item.account_customer_category_id === INDIVIDUAL_ITEMS) {
      incomingItems[0].push(item);
    } else {
      if (!categories[item.account_customer_category_id]) {
        categories[item.account_customer_category_id] = incomingItems.length;
        incomingItems.push([]);
      }
      incomingItems[categories[item.account_customer_category_id]].push(item);
    }
  });

  return incomingItems.flat();
};

const emptyQuote = {
  account_pricemodifier_id: null,
  pricemodifier_name: null,
  pricemodifier_value_flat: null,
  pricemodifier_value_pct: null,
  status: null,
  recipient_message: "",
  due_at: null,
  expected_issue_at: null,
  statement_period_start: null,
  statement_period_end: null,
  series: null,
  items: [],
  customers: [],
};

export interface QuoteState {
  quote: Quote;
  quotePreview: Quote;
}

const initialState: QuoteState = {
  quote: parseQuoteResponseToQuoteState(emptyQuote),
  quotePreview: parseQuoteResponseToQuoteState(emptyQuote),
};

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setQuote: (state, action: PayloadAction<Quote>) => {
      return {
        ...state,
        quote: {
          ...action.payload,
          ...(action?.payload?.customer ? { customers: [action.payload.customer] } : {}),
          items: reorderQuoteItems(action.payload.items || []),
        },
      };
    },
    refetchQuote: (_, action: PayloadAction<Quote | {} | null>) => {
      return {
        quote: parseQuoteResponseToQuoteState(action?.payload ?? {}),
        quotePreview: parseQuoteResponseToQuoteState(action?.payload ?? {}),
      };
    },

    setQuoteCustomers: (state, action: PayloadAction<QuoteCustomer[]>) => {
      return {
        ...state,
        quote: {
          ...state.quote,
          customers: [...action.payload],
        },
        quotePreview: {
          ...state.quotePreview,
          customers: [...action.payload],
        },
      };
    },
    resetQuote: (_, action: PayloadAction<undefined | Partial<Quote>>) => {
      return {
        quote: {
          ...initialState.quote,
          ...(typeof action?.payload === "object" ? action.payload : {}),
        },
        quotePreview: {
          ...initialState.quotePreview,
          ...(typeof action?.payload === "object" ? action.payload : {}),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutThunk.fulfilled, () => initialState)
      .addCase(hydrate, (state, action) => {
        return assignIn(state, action?.payload?.auth);
      });
  },
});

export const { resetQuote, setQuote, setQuoteCustomers, refetchQuote } = quoteSlice.actions;

export default quoteSlice.reducer;
