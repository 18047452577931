import AvatarUploader from "@/components/AvatarUploader";
import useAccount from "@/features/account-management/useAccount";

const EditableAvatar = ({ isEditable }) => {
  const { account } = useAccount();

  if (isEditable) {
    return (
      <AvatarUploader
        size={60}
        orientation="vertical"
        currentAvatar={account?.avatar_url}
        showButtons={false}
        showAsSquare={true}
      />
    );
  } else if (account?.avatar_url) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        style={{
          objectFit: "contain",
          maxHeight: 80,
          maxWidth: 80,
          marginBottom: 10,
        }}
        src={account?.avatar_url}
        alt={account?.public_name}
      />
    );
  } else {
    return null;
  }
};

export default EditableAvatar;
