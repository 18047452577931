import sortBy from "lodash/sortBy";
import { Invoice, InvoiceStatus, InvoiceItem, InvoiceCustomer } from "@/types";
import { DateTime } from "luxon";
import { INDIVIDUAL_ITEMS } from "@/features/invoice-management/invoice-management-reducers";
import { setInvoice } from "@/features/invoice-management/invoice-management-reducers";
import { Dispatch } from "react";
import stringifyQueryParams from "@/utils/stringifyQueryParams";
import isNumeric from "@/utils/isNumeric";

export const invoiceRedirectFilters = (props = {}) =>
  stringifyQueryParams({
    page: 1,
    page_size: 10,
    sort_field: "created_at",
    sort_direction: "DESC",
    ...props,
  });

export const isInvoiceComplete = (invoice: Invoice) => {
  const isStatementPeriodSelected = !!(
    (invoice?.statement_period_start && invoice?.statement_period_end) ||
    (!invoice?.statement_period_start && !invoice?.statement_period_end)
  );

  const isValidIssueDaysBefore = isNumeric(invoice?.series?.issued_days_before_due_date);

  const isRecurringInformationAllSelected =
    !!invoice?.series?.frequency && !!invoice?.series?.frequency_count && isValidIssueDaysBefore;

  const isDueDateInTheFuture =
    (invoice?.status && invoice?.status !== InvoiceStatus.DRAFT) ||
    DateTime.fromJSDate(new Date(invoice?.due_at || "")) > DateTime.now().minus({ days: 1 }).startOf("day");

  return (
    isStatementPeriodSelected &&
    (!invoice?.is_recurring || isRecurringInformationAllSelected) &&
    invoice?.expected_issue_at &&
    invoice?.due_at &&
    invoice?.customers?.length > 0 &&
    isDueDateInTheFuture
  );
};

export const handleAddCustomerToInvoice = (dispatch: Dispatch<any>, customers: InvoiceCustomer[], invoice: Invoice) => {
  let updatedCustomers: InvoiceCustomer[] = [];

  customers?.forEach((customer) => {
    const email = customer?.email || customer?.user?.email;
    const cell_phone = customer?.cell_phone || customer?.user?.cell_phone;

    updatedCustomers =
      updatedCustomers?.map((existingCustomer) => {
        // Check if the customer already exists
        if (existingCustomer.id === customer.id || existingCustomer.email === email) {
          // If the existing customer matches, update the email and cell_phone
          return {
            ...existingCustomer,
            email,
            cell_phone,
          };
        }
        // If the existing customer doesn't match, return it as it is
        return existingCustomer;
      }) ?? [];

    // Check if the customer already exists in the array
    const customerExists = updatedCustomers?.some(
      (existingCustomer) => existingCustomer.id === customer.id || existingCustomer.email === email,
    );

    // If the customer doesn't exist, add it to the end of the array
    if (!customerExists) {
      updatedCustomers?.push({
        ...customer,
        email,
        cell_phone,
      });
    }
  });

  dispatch(
    setInvoice({
      ...invoice,
      has_changed: true,
      customers: updatedCustomers,
    }),
  );
};

export const getInvoiceAction = (invoice: Invoice) => {
  const today = DateTime.now().startOf("day");
  const issueDate = DateTime.fromJSDate(
    // @ts-ignore
    new Date(invoice?.expected_issue_at),
  ).startOf("day");

  const isInvoiceIssued = invoice?.status === InvoiceStatus.ISSUED || invoice?.status === InvoiceStatus.PAST_DUE;

  if (invoice?.status === InvoiceStatus.DRAFT) {
    if (isInvoiceComplete(invoice)) {
      return issueDate >= today ? "schedule" : "now";
    }
    return "draft";
  } else if (invoice?.sync_mode !== "always" && invoice.has_changed && invoice?.customers?.length === 1) {
    if (isInvoiceIssued) {
      return "save-resend";
    }

    return "save";
  } else if (invoice?.status === InvoiceStatus.SCHEDULED) {
    if (invoice.has_changed && issueDate.toFormat("yyyy-MM-dd") === today.toFormat("yyyy-MM-dd")) {
      return "save-send";
    } else if (invoice.has_changed && issueDate > today) {
      return "save";
    }
    return "now";
  } else if (isInvoiceIssued) {
    return "resend";
  }

  return "save";
};

export const getActionButtonText = (invoice: Invoice) => {
  const action = getInvoiceAction(invoice);
  const today = DateTime.now().toFormat("yyyy-MM-dd");

  if (action === "save-resend") return "Save & Resend";
  if (action === "save" && invoice.has_changed) {
    if (today !== invoice?.expected_issue_at) {
      return "Save & Schedule";
    } else {
      return "Save Changes";
    }
  }
  if (action === "save-send") return "Save & Send Now";
  if (action === "draft") return "Save Draft";

  if (action === "resend") return "Resend";
  if (action === "schedule") return "Save & Schedule";
  if (action === "now") return "Send Now";
};

export const calculateItemGroup = (items: InvoiceItem[] = []) => {
  const c: { [x: string]: InvoiceItem[] } = {
    [INDIVIDUAL_ITEMS]: [],
  };
  const categorySortOrder = {
    [INDIVIDUAL_ITEMS]: 0,
  };
  items?.forEach((i, idx) => {
    if (i.account_customer_category_id && !c[i.account_customer_category_id]) {
      c[i.account_customer_category_id] = [];
      if (i.account_customer_category_id !== INDIVIDUAL_ITEMS) {
        categorySortOrder[i.account_customer_category_id] = Object.keys(categorySortOrder).length;
      }
    }

    c[i.account_customer_category_id || INDIVIDUAL_ITEMS].push({
      ...i,
      sort_order: idx,
    });
  });

  const categories = sortBy(Object.keys(c), (k) => categorySortOrder[k]);

  return {
    categories,
    itemDict: c,
    categorySortOrder,
  };
};
