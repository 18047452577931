import { useState } from "react";
import { useSnackbar } from "notistack";

export const replaceAtIndex = <T>(index: number, value: any, array: T[]): T[] => {
  const newArray = array.slice();
  newArray.splice(index, 1, value);
  return newArray;
};

export const removeAtIndex = <T>(index: number, array: T[]): T[] => {
  const newArray = array.slice();
  newArray.splice(index, 1);
  return newArray;
};

export const insertAtIndex = <T>(index: number, value: any, array: T[]): T[] => {
  const newArray = array.slice();
  newArray.splice(index, 0, value);
  return newArray;
};

export const replaceOrAdd = <T>(value: T, array: T[], key?: string | any | null): T[] => {
  const list = array?.length ? array : [];
  let existingIndex = -1;

  if (!Boolean(key)) {
    existingIndex = list.indexOf(value);
  } else if (typeof key === "function") {
    existingIndex = list.findIndex(key);
  } else {
    existingIndex = list.findIndex((val) => val[key] === value[key]);
  }

  const updated =
    existingIndex > -1 ? replaceAtIndex(existingIndex, value, array) : insertAtIndex(array.length, value, array);

  return updated;
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from<T>(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return [...result];
};

export const useLimitedArrayState = <T>(
  initialState: T[],
  arrayLimit: number,
  key: string,
): [T[], (newState: T[]) => void] => {
  const [state, setState] = useState(initialState);
  const { enqueueSnackbar } = useSnackbar();

  const updateState = (newState) => {
    if (newState.length > arrayLimit) {
      enqueueSnackbar(`You can only add ${arrayLimit} ${arrayLimit === 1 ? key : `${key}s`} to this list.`, {
        variant: "error",
      });
    } else {
      setState(newState);
    }
  };

  return [state, updateState];
};

export const hasIntersection = (array1: any[], array2: any[]): boolean => {
  return array1.some((item) => array2.includes(item));
};
