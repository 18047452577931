import React, { useMemo, useState, useEffect } from "react";
import Head from "next/head";
import { IconButton, PaletteMode, useMediaQuery } from "@mui/material";
import { AppProps } from "next/app";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { Provider } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { Poppins } from "next/font/google";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider as ModalProvider } from "@ebay/nice-modal-react";
import { SnackbarProvider, closeSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/CloseRounded";
import getThemeOptions from "@/config/theme";
import createEmotionCache from "@/config/createEmotionCache";
import { wrapper } from "@/config/store";
import "@/features/layout/layout-dialogs";
import Router, { useRouter } from "next/router";
import { PlaidWidget } from "@/features/wallet/components/PlaidWidget";
import { initLogRocket } from "@/utils/logrocket";
import Script from "next/script";
import { datadogLogs } from "@datadog/browser-logs";
import { setColorMode } from "@/features/layout/layout-reducers";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GenericLayout from "@/features/layout/components/GenericLayout";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@/components/Link";
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addLocale(en);

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["200", "400", "500", "600", "700"],
});

const clientSideEmotionCache = createEmotionCache();

interface FinliAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true, error };
  }
  componentDidCatch(_error, _errorInfo) {
    // You can use your own error logging service here
  }
  render() {
    // Check if the error is thrown
    // @ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Head>Error - Finli</Head>
          <GenericLayout is404>
            <Box sx={{ textAlign: "center", marginTop: "4rem" }}>
              <Typography variant="h1" sx={{ fontSize: "7rem" }}>
                Oh no!
              </Typography>
              <Typography variant="body1" sx={{ marginY: "2rem" }}>
                We're sorry but this page has an error. We've been alerted and we're actively working on it.
              </Typography>
              <Link
                onClick={() => Router.back()}
                sx={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ChevronLeftRounded fontSize="small" />
                Click Here to Go Back
              </Link>
            </Box>
          </GenericLayout>
        </>
      );
    }

    // Return children components in case of no error

    // @ts-ignore
    return this.props.children;
  }
}

const animation = {
  name: "Fade Transition",
  variants: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
  transition: {
    duration: 0.25,
  },
};

const getPreferredTheme = (): PaletteMode => {
  if (typeof window !== "undefined" && window.matchMedia) {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return "light";
    }
  }
  return "light";
};

const Finli = ({ Component, ...rest }: FinliAppProps) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            networkMode: "always",
            staleTime: 60 * 1000,
          },
          mutations: {
            networkMode: "always",
          },
        },
      }),
  );
  const [isLoading, setIsLoading] = useState(false);
  const selectedColorMode = store?.getState()?.layout?.colorMode as PaletteMode;
  const primaryColor = store?.getState()?.layout?.theme?.primaryColor;
  const secondaryColor = store?.getState()?.layout?.theme?.secondaryColor;

  useEffect(() => {
    store.dispatch(setColorMode(getPreferredTheme()));
  }, []);

  const theme = useMemo(
    () => createTheme(getThemeOptions(getPreferredTheme(), primaryColor, secondaryColor)),
    [selectedColorMode, primaryColor, secondaryColor],
  );

  const handleRouteChangeStart = (_, { shallow }) => {
    if (!shallow) {
      setIsLoading(true);
    }
  };

  const handleRouteChangeComplete = () => {
    setIsLoading(false);
  };

  const handleRouteChangeError = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    initLogRocket();
    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeError);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeError", handleRouteChangeError);
    };
  }, [router]);

  useEffect(() => {
    // @ts-ignore
    if (["dev", "staging", "production"].includes(process.env.ENV_NAME)) {
      datadogLogs.init({
        clientToken: "pubee53ec68576108eefb02f0b419fce34a",
        site: "us5.datadoghq.com",
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        beforeSend: (log) => {
          return !['Uncaught "Script error."', "XHR error POST https://px.ads.linkedin.com/wa/"].includes(log.message);
        },
      });
    }
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_ID as string}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Provider store={store}>
            <Script
              strategy="afterInteractive"
              src="https://c.la1-c1-ia7.salesforceliveagent.com/content/g/js/56.0/deployment.js"
              onReady={() => {
                const initializeAgent = () => {
                  window?.liveagent?.init(
                    "https://d.la1-c1-ia7.salesforceliveagent.com/chat",
                    "572Dn000000xl6P",
                    "00DDn000006D3I1",
                  );

                  if (!window._laq) {
                    window._laq = [];
                  }

                  window._laq.push(function () {
                    window?.liveagent?.showWhenOffline(
                      "573Dn000000xlBI",
                      document.getElementById("liveagent_button_offline_ID"),
                    );
                    window?.liveagent?.showWhenOnline(
                      "573Dn000000xlBI",
                      document.getElementById("liveagent_button_online_ID"),
                    );
                  });
                };

                if (typeof window !== "undefined" && typeof window?.liveagent !== "undefined") {
                  initializeAgent();
                }
              }}
            />
            <Script src="https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js" async={true} />
            {process.env.ENV_NAME === "production" && (
              <>
                <Script id="google-tag-manager" strategy="afterInteractive">
                  {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${process.env.GOOGLE_TAG_MANAGER_ID_V3}');`}
                </Script>
                <Script
                  id="gtm-v4"
                  strategy="afterInteractive"
                  src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_TAG_MANAGER_ID_V4}`}
                />
                <Script id="google-tag-manager-v4" strategy="afterInteractive">
                  {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${process.env.GOOGLE_TAG_MANAGER_ID_V4}');`}
                </Script>
              </>
            )}
            <Head>
              <meta name="viewport" content="initial-scale=1, width=device-width" />
            </Head>
            <CacheProvider value={emotionCache}>
              <CssBaseline />
              <ErrorBoundary>
                <ThemeProvider theme={theme}>
                  <style jsx global>{`
                    html {
                      font-family: ${poppins.style.fontFamily};
                    }
                    .notistack-SnackbarContainer {
                      z-index: 1400 !important;
                    }
                  `}</style>
                  <SnackbarProvider
                    maxSnack={1}
                    autoHideDuration={4000}
                    disableWindowBlurListener
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: isMobile ? "center" : "right",
                    }}
                    style={{
                      display: "flex",
                      marginTop: isMobile ? "62px" : "84px",
                      maxWidth: isMobile ? "100%" : "700px",
                      whiteSpace: "break-spaces",
                      minWidth: "343px",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      borderRadius: `${theme.shape.borderRadius}px`,
                    }}
                    action={(snackbarId) => (
                      <IconButton
                        onClick={() => closeSnackbar(snackbarId)}
                        size="small"
                        color="inherit"
                        title="Dismiss Notification"
                      >
                        <CloseIcon fontSize="small" sx={{ fontSize: "1.125rem" }} />
                      </IconButton>
                    )}
                  >
                    <PlaidWidget />
                    <LazyMotion features={domAnimation}>
                      <AnimatePresence>
                        {isLoading && (
                          <m.div
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "fixed",
                              zIndex: 12,
                              height: "100%",
                              width: "100%",
                              top: 0,
                              left: 0,
                            }}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            variants={animation.variants}
                            transition={animation.transition}
                          >
                            <CircularProgress color="inherit" />
                          </m.div>
                        )}
                      </AnimatePresence>
                    </LazyMotion>
                    <Component {...pageProps} />
                    <ModalProvider />
                  </SnackbarProvider>
                </ThemeProvider>
              </ErrorBoundary>
            </CacheProvider>
          </Provider>
        </Hydrate>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};

export default Finli;
