import { useQuery } from "@tanstack/react-query";
import { Query, Partnership } from "@/types";
import useAppSelector from "@/hooks/useAppSelector";
import { fetchPartnershipDetails } from "./partner-management-queries";
import { selectPartnershipId } from "@/features/auth/auth-selectors";
import { selectIsPartner } from "../layout/layout-selectors";

interface UsePartnership {
  partnership: Partnership | null;
}

const usePartnership = (): UsePartnership => {
  const partnershipId = useAppSelector(selectPartnershipId);
  const hasPartnerTheme = useAppSelector(selectIsPartner);

  const { data: partnership } = useQuery<Partnership | null, Error>(
    [Query.partnership.PARTNERSHIP_INFO],
    () => fetchPartnershipDetails(),
    {
      initialData: null,
      retry: 2,
      keepPreviousData: true,
      enabled: !!partnershipId,
    },
  );

  return {
    partnership,
  };
};

export default usePartnership;
