import { Quote } from "@/types";
import { INDIVIDUAL_ITEMS } from "@/features/quote-management/quote-management-reducers";

export const adaptOutgoingQuote = (quote: Quote) => {
  const {
    customers,
    quote_items = quote?.items || quote?.quote_items || [],
    /* eslint-disable */
    account,
    use_statement_period,
    has_changed,
    allowableActions,
    is_readonly,
    /* eslint-enable */

    id,
    account_pricemodifier_id,
    pricemodifier_name,
    pricemodifier_value_flat,
    pricemodifier_value_pct,
    recipient_message,
    internal_note,
  } = quote;

  return {
    quote: {
      id,
      account_customer_id: customers?.[0]?.id ?? null,
      account_pricemodifier_id,
      pricemodifier_name,
      pricemodifier_value_flat: pricemodifier_value_flat || null,
      pricemodifier_value_pct: pricemodifier_value_pct || null,
      recipient_message,
      internal_note,

      items: (quote_items || []).map((item) => {
        const {
          id,
          account_customer_category_id,
          /* eslint-disable-next-line  */
          account_customer_category_name,
          account_priceitem_id,
          name,
          price,
          description,
          account_pricemodifier_id,
          pricemodifier_name,
          pricemodifier_value_pct,
          pricemodifier_value_flat,
          quantity_measurement,
          quantity,
        } = item;

        return {
          id: id || undefined,
          account_customer_category_id:
            account_customer_category_id === INDIVIDUAL_ITEMS ? null : account_customer_category_id,
          account_priceitem_id,
          name,
          price,
          description,
          account_pricemodifier_id,
          pricemodifier_name,
          pricemodifier_value_pct:
            typeof pricemodifier_value_pct === "string" ? +pricemodifier_value_pct : pricemodifier_value_pct || null,
          pricemodifier_value_flat:
            typeof pricemodifier_value_flat === "string" ? +pricemodifier_value_flat : pricemodifier_value_flat || null,
          quantity_measurement,
          quantity: +quantity,
        };
      }),
    },
  };
};
