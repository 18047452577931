import initApi, { routes } from "@/config/api";
import { PaidOfflineType, PaymentCreateMethod } from "@/types";
import { downloadFromBlob } from "@/utils/download";
import { GetServerSidePropsContext } from "next";

export const fetchPayments = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.account.payments(filters))
    .then(({ data }) => data?.data);

export const exportPayments = async (filters) =>
  await initApi()
    .get(routes.payments.export(filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const exportUserPayments = async (filters) =>
  await initApi()
    .get(routes.user.paymentExport(filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const editOfflinePayment = async ({
  paymentId,
  paid_offline_type,
  payment_note,
  payment_date,
}: {
  paymentId: string;
  paid_offline_type: PaidOfflineType;
  payment_note: string;
  payment_date: string;
}) =>
  await initApi()
    .patch(routes.payments.payment(paymentId), {
      paid_offline_type,
      payment_note,
      payment_date,
    })
    .then(({ data }) => data?.data);

export const editPblPayment = async ({
  paymentId,
  scheduled_at,
  wallet_instrument_id,
  isBackdoor = false,
}: {
  paymentId: string;
  scheduled_at?: string | undefined;
  wallet_instrument_id?: string | undefined;
  isBackdoor?: boolean;
}) => {
  return await initApi()
    .patch(isBackdoor ? routes.payments.bdpayment(paymentId) : routes.payments.payment(paymentId), {
      scheduled_at,
      wallet_instrument_id,
    })
    .then(({ data }) => data?.data);
};

export const deletePayment = async (paymentId, isBackdoor = false) =>
  await initApi()
    .delete(isBackdoor ? routes.payments.bdpayment(paymentId) : routes.payments.payment(paymentId))
    .then(() => ({ deleted: true, message: "Payment deleted successfully" }))
    .catch(() => ({ deleted: false, message: "Payment could not be deleted" }));

export const deleteUserPayment = async (paymentSeriesId: string) =>
  await initApi()
    .post(routes.user.deletePaymentSeries(paymentSeriesId))
    .then(() => ({ deleted: true, message: "Payment deleted successfully" }))
    .catch(() => ({ deleted: false, message: "Payment could not be deleted" }));

export const refundPayment = async (paymentId) =>
  await initApi()
    .post(routes.payments.refund(paymentId))
    .then(({ data }) => data?.data);

export const createPayment = async ({
  requestBody,
  target,
  method,
  isBackdoor = false,
  recaptchaToken,
}: {
  requestBody: any;
  target: { type: string; id: string };
  method: PaymentCreateMethod;
  isBackdoor?: boolean;
  recaptchaToken?: string | null;
}) => {
  return await initApi()
    .post(
      isBackdoor ? routes.payments.bdcreate(method) : routes.payments.create(method),
      {
        ...requestBody,
        target,
      },
      {
        ...(recaptchaToken ? { headers: { "recaptcha-token": recaptchaToken as string } } : {}),
      },
    )
    .then(({ data }) => data?.data?.payment);
};

export const createApplePaySession = async (url: string) => {
  return await initApi()
    .post(routes.payments.applePaySession, { url })
    .then(({ data }) => data?.data);
};

export const payInvoice = async ({
  invoiceId,
  instrumentId,
  scheduledAt,
}: {
  invoiceId: string;
  instrumentId: string;
  scheduledAt?: string;
}) =>
  await initApi()
    .post(routes.invoice.payment(invoiceId), {
      wallet_instrument_id: instrumentId,
      scheduled_at: scheduledAt,
    })
    .then(({ data }) => data?.data);

export const createOfflinePayment = async ({
  invoiceId,
  paid_offline_type,
  payment_note,
  payment_date,
}: {
  invoiceId: string;
  paid_offline_type: PaidOfflineType;
  payment_note: string;
  payment_date: string;
}) =>
  await initApi()
    .post(routes.invoice.payment(invoiceId), {
      paid_offline_type,
      payment_note,
      payment_date,
    })
    .then(({ data }) => data?.data);

export const fetchPayByQrCode = async () => {
  return await initApi()
    .get(routes.account.paymentQrCode)
    .then(({ data }) => data?.data);
};
