import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import FormattedNumber from "@/components/FormattedNumber";
import Link from "@/components/Link";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { useRouter } from "next/router";
import { invoiceRedirectFilters } from "@/utils/invoices";
import TestId from "@/constants/testIds";

export default NiceModal.create<{
  customerName: string;
  invoiceTotal: number;
}>(({ customerName, invoiceTotal }) => {
  const modal = useModal();
  const router = useRouter();

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={() => {
        modal.hide();
      }}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogContent sx={{ textAlign: "center" }} dividers>
        <Box maxWidth="75%" margin="0 auto">
          <Typography variant="h1">
            <CheckIcon sx={{ color: "success.light", fontSize: "4rem" }} />
          </Typography>
          <Typography variant="h5">Invoice sent successfully!</Typography>
          <Typography variant="body2" marginTop="0.75rem">
            Invoice Amount
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            <FormattedNumber prefix="$" value={invoiceTotal} />
          </Typography>
          <Typography variant="body2" marginTop="0.75rem">
            Bill to
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {customerName}
          </Typography>
          <Typography variant="body2" margin="0.75rem 0 0.5rem">
            You can view the status of this
            <br />
            invoice in the{" "}
            <Link
              onClick={() => {
                modal.hide();
                router.push(`/invoices${invoiceRedirectFilters()}`);
              }}
            >
              Invoice Tab
            </Link>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="center" width="100%">
          <AffirmativeButton
            fullWidth
            onClick={() => {
              modal.resolve();
              modal.hide();
            }}
            sx={{ maxWidth: "60%" }}
            data-testid={TestId.Dialog.InvoiceConfirmSentSuccess}
          >
            Done!
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
